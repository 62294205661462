<template>
  <div class="container">
    <img
      src="http://shop.shangjiu-gift.com.cn/Templates/14/images/limg.jpg"
      alt=""
    />
    <div class="login">
      <div class="login-input">
        <div>账号</div>
        <input type="text" />
      </div>
      <div class="login-input">
        <div>密码</div>
        <input type="text" />
      </div>
      <div class="login-input">
        <div>验证码</div>
        <div class="yzm">
          <input type="text" />
          <img
            src="http://shop.shangjiu-gift.com.cn/API/Site/Member/validatecode?0.8071419509077185?0.769071807473577?0.7729854358538226"
            alt=""
          />
        </div>
      </div>
      <img
        class="login-btn"
        src="	http://shop.shangjiu-gift.com.cn/Templates/14/images/btn.jpg"
        alt=""
      />
      <div class="reg-btn">
        还没有账号？ <span @click="to('/register')">注册</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    to(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 50px;
  .login {
    flex: 1;
    border: solid 1px #e6e6e6;
    color: #999;
    padding: 10px 50px;
    font-size: 13px;
    .login-input {
      width: 100%;
      margin-bottom: 20px;
      input {
        width: 100%;
        height: 25px;
        border: solid 1px #ccc;
        outline: 0;
        margin-top: 2px;
      }
      .yzm {
        display: flex;
      }
    }
    .login-btn {
      cursor: pointer;
      margin-top: 20px;
    }
    .reg-btn {
      color: #999999;
      margin-top: 10px;
      span {
        cursor: pointer;
        color: #df3133;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>